/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-max-depth */
/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import telegram from '../../images/telegram.png';
import whatsapp from '../../images/whatsapp.png';

export default function MinhasRedes({ open, setOpen }) {
  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={ open }
      onClose={ handleClose }
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Minhas Redes</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="scroll-dialog-description"
          ref={ descriptionElementRef }
          tabIndex={ -1 }
        >
          <>
            <span className="font-bold">
              Entre no nosso grupo do Whatsapp agora mesmo e acompanhe as novidades!
            </span>
            <br />
            <button
              onClick={ () => window.open('https://chat.whatsapp.com/JgNYequvFQqLinyGlXSnoV') }
            >
              <img className="w-32" src={ whatsapp } alt="whatsapp" />
            </button>
            <br />
            <span className="font-bold">
              Entre no nosso grupo do Telegram agora mesmo e veja em tempo real todas as casas que estão pagando mais e que têm mais chances de ganhar! Não perca!
            </span>
            <br />
            <button
              onClick={ () => window.open('https://t.me/slotsprime') }
            >
              <img className="w-32" src={ telegram } alt="telegram" />
            </button>
          </>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={ handleClose }>Fechar</Button>
      </DialogActions>
    </Dialog>
  );
}
