/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-max-depth */
/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ComoFunciona({ open, setOpen }) {
  const teste = (
    <>
      <span className="font-bold">
        Minutos Pagantes: Desvendando os Melhores Momentos para Ganhar
      </span>
      <br />
      <br />
      <span className="font-bold">
        Introdução
      </span>
      <br />
      <br />
      Os Minutos Pagantes referem-se a momentos específicos durante o dia em que um jogo tende a oferecer prêmios mais altos aos jogadores. Neste artigo, vamos direto ao ponto, sem enrolação, para apresentar os minutos ideais para aumentar seus ganhos.
      <br />
      <br />
      <span className="font-bold">
        Quais os Minutos Pagantes?
      </span>
      <br />
      <br />
      A recomendação é clara: conheça os melhores horários para jogar em 2024. Isso proporciona uma visão mais clara de quando o jogo
      oferece maiores pagamentos. Abaixo, apresentamos em formato de tabela os minutos em que o jogo costuma pagar mais ao longo do
      dia.
      <br />
      <br />
      <span className="font-bold">
        Quais os Minutos Pagantes?
      </span>
      <br />
      <br />
      Caso queira estar sempre atualizado nos horários mais lucrativos, acesse o site abaixo para obter informações sobre os melhores
      momentos.
      <br />
      <br />
      <span className="font-bold">
        [HORÁRIOS SEMPRE ATUALIZADOS DOS MINUTOS PAGANTES]
      </span>
      <br />
      <br />
      <span className="font-bold">
        Conclusão
      </span>
      <br />
      <br />
      Os Minutos Pagantes tem, de fato, momentos ao longo do dia em que tendem a proporcionar maiores ganhos. Destacamos os momentos
      mais promissores para potencializar seus lucros.
      <br />
      <br />
      Esperamos que essas informações ajudem você a aproveitar ao máximo sua experiência nos jogos. Bons momentos e grandes ganhos!
      <br />
      <br />
      Jogue com responsabilidade! Aposta NÃO É renda extra!
    </>

  );

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={ open }
      onClose={ handleClose }
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Como Funciona</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="scroll-dialog-description"
          ref={ descriptionElementRef }
          tabIndex={ -1 }
        >
          {teste}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={ handleClose }>Fechar</Button>
      </DialogActions>
    </Dialog>
  );
}
