/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Button, ButtonGroup, ThemeProvider, createTheme } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import InfoIcon from '@mui/icons-material/Info';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ComoFunciona from './ComoFunciona';
import MinhasRedes from './MinhasRedes';
import MobileMenu from './MobileMenu';

const theme = createTheme({
  palette: {
    btnColor: {
      main: '#ffef00',
    },
  },
});

export default function MenuHeader() {
  const [openComoFunciona, setOpenComoFunciona] = useState(false);
  const [openRedes, setOpenRedes] = useState(false);
  const mapMenu = [
    {
      name: 'Como Funciona',
      icon: <InfoIcon />,
    },
    {
      name: 'Minhas Redes',
      icon: <PeopleAltIcon />,
    },
    {
      name: 'Sistema Vip',
      icon: <StarIcon />,
    },
  ];

  return (
    <ThemeProvider theme={ theme }>
      <MobileMenu
        setOpenComoFunciona={ setOpenComoFunciona }
        setOpenRedes={ setOpenRedes }
      />
      <div
        className="lg:flex hidden h-6 mr justify-center items-center"
      >
        <ButtonGroup variant="text" aria-label="Basic button group">
          {
            mapMenu.map((e, i) => (
              <div
                key={ i }
                className="transform transition-transform hover:scale-105 text-black"
              >
                <Button
                  variant="contained"
                  endIcon={ e.icon }
                  color="btnColor"
                  onClick={ () => {
                    if (e.name === 'Como Funciona') {
                      setOpenComoFunciona(true);
                    }
                    if (e.name === 'Minhas Redes') {
                      setOpenRedes(true);
                    }
                  } }
                >
                  {e.name}
                </Button>
              </div>
            ))
          }
        </ButtonGroup>
        <ComoFunciona open={ openComoFunciona } setOpen={ setOpenComoFunciona } />
        <MinhasRedes open={ openRedes } setOpen={ setOpenRedes } />
      </div>
    </ThemeProvider>
  );
}
