import React from 'react';
import MenuHeader from './MenuHeader';
import logo from '../../images/logo-minutosvip.png';
import Ganhos from './Ganhos';

export default function Header() {
  return (
    <div
      className="bg-black"
      style={ { position: 'fixed', width: '100%', top: 0, zIndex: 1000 } }
    >
      <div
        className="flex justify-between items-center p-2 bg-black text-white"
      >
        <div className="flex justify-center items-center">
          <img className="lg:w-44 w-32" src={ logo } alt="ficha-cassino" />
        </div>
        <MenuHeader />
      </div>
      <Ganhos />
    </div>
  );
}
