import React, { useState } from 'react';
import { Button, ButtonGroup, ThemeProvider, createTheme } from '@mui/material';
import ImagesArrPg from './ImagesArrPg';
import ImagesArrPragmatic from './ImagesArrPragmatic';
import UsuariosUtilizando from './UsuariosUtilizando';

export default function SelectPlataform() {
  const [plataform, setPlatafomr] = useState(0);

  const theme = createTheme({
    palette: {
      btnColor: {
        main: '#ffef00',
      },
      btnColorOff: {
        main: '#eeef00',
      },
    },
  });

  const pg = () => {
    setPlatafomr(0);
  };

  const pragmatic = () => {
    setPlatafomr(1);
  };
  return (
    <ThemeProvider theme={ theme }>
      <div className="bg-black flex flex-col items-center justify-center p-b w-full">
        {/* <ImagesArrPragmatic />
      <ImagesArrPg /> */}
        <ButtonGroup
          className="w-full flex justify-center mb-3"
          aria-label="Basic button group"
        >
          <Button
            className={ `${plataform === 0 ? 'text-black' : ''} md:w-[300px] w-[40%]` }
            variant={ `${plataform === 0 ? 'contained' : 'outlined'}` }
            color={ `${plataform === 0 ? 'btnColor' : 'btnColorOff'}` }
            onClick={ pg }
          >
            PGSoft
          </Button>
          <Button
            className={ `${plataform === 1 ? 'text-black' : ''} md:w-[300px] w-[40%]` }
            variant={ `${plataform === 1 ? 'contained' : 'outlined'}` }
            color={ `${plataform === 1 ? 'btnColor' : 'btnColorOff'}` }
            onClick={ pragmatic }
          >
            Pragmatic
          </Button>
        </ButtonGroup>
        <UsuariosUtilizando />
        { plataform === 0 ? (
          <ImagesArrPg />
        ) : (
          <ImagesArrPragmatic />
        )}
      </div>
    </ThemeProvider>
  );
}
